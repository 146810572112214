import { ThemeProvider } from "@mui/material";
import { christmasFont } from "../theme/themes";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useSecretSantaList, useSelectSecretSanta } from "../data/queries";

const SecretSanta = () => {
  const skeletonButtons = new Array(7);
  skeletonButtons.fill(" ");

  const {
    isLoading: secretSantaListIsLoading,
    isError: secretSantaListIsError,
    data: secretSantaList,
  } = useSecretSantaList();

  const {
    isLoading: selectSecretSantaIsLoading,
    isError: selectSecretSantaIsError,
    mutate,
    data: selectedSecretSanta,
  } = useSelectSecretSanta();

  const onClick = (selectedMemberId) => {
    const matchingMember = secretSantaList.find(
      (member) => member.id === selectedMemberId
    );

    // update the secretSantaList object directly to trigger a re-render
    if (matchingMember) {
      matchingMember.hasPickedSecretSanta = true;
    }

    mutate(selectedMemberId);
  };

  // TODO Move alerts to the root and trigger display on error
  return (
    <Paper elevation={4} sx={{ padding: 4, margin: "20px 0px" }}>
      <Stack spacing={2}>
        {secretSantaListIsError ? (
          <Alert severity="error">
            Failed to get Secret Santa list, please refresh the page
          </Alert>
        ) : null}
        {selectSecretSantaIsError ? (
          <Alert severity="error">
            The elves got an error, please refresh the page and try again
          </Alert>
        ) : null}
        {selectSecretSantaIsLoading ? (
          <ThemeProvider theme={christmasFont}>
            <Typography>Santa's elves are making a selection...</Typography>
          </ThemeProvider>
        ) : selectedSecretSanta ? (
          <ThemeProvider theme={christmasFont}>
            <Typography variant="h3" color="primary" align="center">
              Santa's elves have selected...
            </Typography>
            <Typography variant="h2" color="secondary.dark" align="center">
              {selectedSecretSanta.name}
            </Typography>
          </ThemeProvider>
        ) : (
          <>
            <ThemeProvider theme={christmasFont}>
              <Typography variant="h3" color="primary" align="center">
                If you are ready to select your Christmas gift recipient click
                your name below.
              </Typography>
            </ThemeProvider>
            <Typography variant="h5" color="primary.dark" align="center">
              Once a recipient has been selected you won't be able pick again,
              so be sure to remember your gift recipient
            </Typography>
          </>
        )}
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {!secretSantaListIsLoading
            ? secretSantaList?.map((santa, index) => {
                return santa.isAdult ? (
                  <Grid item key={santa.id} xs={6} sm={4} md={3} lg={2}>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ minWidth: { xs: 120, sm: 150 } }}
                      disabled={santa.hasPickedSecretSanta}
                      onClick={() => onClick(santa.id)}
                    >
                      {santa.name}
                    </Button>
                  </Grid>
                ) : null;
              })
            : skeletonButtons.map((element, index) => (
                <Grid item key={index} xs={6} sm={4} md={3} lg={2}>
                  <Skeleton
                    sx={{
                      marginRight: 10,
                      minWidth: { xs: 122, sm: 152 },
                      height: 84,
                    }}
                  />
                </Grid>
              ))}
        </Grid>
      </Stack>
    </Paper>
  );
};

export default SecretSanta;
