import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";

const ChristmasListSkeleton = () => {
  const skeletonBlocks = new Array(9);
  skeletonBlocks.fill(" ");

  return (
    <>
      {skeletonBlocks.map((skeleton, index) => (
        <Paper key={index} elevation={4} sx={{ padding: 4 }}>
          <Skeleton width={120} height={100} />
          <Skeleton height={40} sx={{ width: 1 }} />
          <Skeleton width={102} height={64} />
        </Paper>
      ))}
    </>
  );
};

export default ChristmasListSkeleton;
