import axios from "axios";

export const getSecretSantaList = async () => {
  const { data } = await axios.get(
    "https://app-secretsanta-api.azurewebsites.net/api/SecretSanta",
    {
      withCredentials: false,
    }
  );
  return data;
};

export const selectSecretSanta = async (memberId) => {
  const { data } = await axios.get(
    `https://app-secretsanta-api.azurewebsites.net/api/SecretSanta/random/${memberId}`,
    {
      withCredentials: false,
    }
  );
  return data;
};

export const getChristmasListById = async (memberId, year) => {
  const { data } = await axios.get(
    `https://app-secretsanta-api.azurewebsites.net/api/ChristmasList/${year}/family/${memberId}`,
    {
      withCredentials: false,
    }
  );
  return data;
};

export const postChristmasList = async (christmasList) => {
  const { data } = await axios.post(
    `https://app-secretsanta-api.azurewebsites.net/api/ChristmasList`,
    christmasList,
    {
      withCredentials: false,
    }
  );
  return data;
};

export const putChristmasList = async (christmasList) => {
  const { data } = await axios.put(
    `https://app-secretsanta-api.azurewebsites.net/api/ChristmasList`,
    christmasList,
    {
      withCredentials: false,
    }
  );
  return data;
};
