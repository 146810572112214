import { createTheme } from "@mui/material";

const christmasPalette = {
  palette: {
    primary: {
      main: "#ac1818",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#4FB443",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    tertiary: {
      main: "#4FB443",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
};

export const christmas = createTheme({
  typography: {
    fontFamily: [
      "Sans-Serif",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
    ].join(","),
  },
  ...christmasPalette,
});

export const christmasFont = createTheme({
  typography: {
    fontFamily: ["SnowyChristmas"].join(","),
    h1: {
      fontSize: 110,
      lineHeight: 0.75,
    },
    h2: {
      fontSize: 90,
      lineHeight: 0.75,
    },
    h3: {
      fontSize: 75,
      lineHeight: 0.75,
    },
    h4: {
      fontSize: 65,
      lineHeight: 0.75,
    },
  },
  ...christmasPalette,
});
