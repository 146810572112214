import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ChristmasList from "./ChristmasList";
import ChristmasListSkeleton from "./ChristmasListSkeleton";
import { useSecretSantaList } from "../data/queries";

const ChristmasLists = ({ year }) => {
  const {
    isLoading: secretSantaListIsLoading,
    isError: secretSantaListIsError,
    data: secretSantaList,
  } = useSecretSantaList();

  // TODO Move alerts to the root and trigger display on error
  return (
    <Stack spacing={2} sx={{ margin: "20px 0px" }}>
      {secretSantaListIsError ? (
        <Alert severity="error">
          Failed to get Christmas lists, please refresh the page
        </Alert>
      ) : null}
      {!secretSantaListIsLoading ? (
        secretSantaList.map((santa) => (
          <ChristmasList key={santa.id} year={year} {...santa} />
        ))
      ) : (
        <ChristmasListSkeleton />
      )}
    </Stack>
  );
};

export default ChristmasLists;
