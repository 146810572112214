import {
  getChristmasListById,
  getSecretSantaList,
  postChristmasList,
  putChristmasList,
  selectSecretSanta,
} from "./api";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useSecretSantaList = () => {
  return useQuery("GetSecretSantaList", getSecretSantaList);
};

export const useSelectSecretSanta = (id) => {
  return useMutation({
    mutationFn: (id) => selectSecretSanta(id),
  });
};

export const useChristmasListById = ({ id, year }) => {
  return useQuery(["GetChristmasListById", id, year], () =>
    getChristmasListById(id, year)
  );
};

export const useCreateChristmasList = (christmasList) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (christmasList) => postChristmasList(christmasList),
    onSuccess: (christmasList) => {
      queryClient.invalidateQueries({
        queryKey: [
          "GetChristmasListById",
          christmasList.id, // this might be a bug since the ID is null on post
          christmasList.year,
        ],
      });
    },
  });
};

export const useUpdateChristmasList = (christmasList) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (christmasList) => putChristmasList(christmasList),
    onSuccess: (christmasList) => {
      queryClient.invalidateQueries({
        queryKey: [
          "GetChristmasListById",
          christmasList.id,
          christmasList.year,
        ],
      });
    },
  });
};
