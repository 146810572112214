import { Skeleton, ThemeProvider } from "@mui/material";
import { christmasFont } from "../theme/themes";
import { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import {
  useChristmasListById,
  useCreateChristmasList,
  useUpdateChristmasList,
} from "../data/queries";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const ChristmasList = ({ id, name, year }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [hasList, setHasList] = useState(false);
  const [editorValue, setEditorValue] = useState({
    year,
    gifts: "",
    familyMember: { id },
  });

  const {
    isLoading: christmasListIsLoading,
    isError: christmasListIsError,
    data: christmasList,
    isFetching,
  } = useChristmasListById({ id, year });

  const { isError: createChristmasListIsError, mutate: createChristmasList } =
    useCreateChristmasList();

  const { isError: updateChristmasListIsError, mutate: updateChristmasList } =
    useUpdateChristmasList();

  // sanitize html so it can be injected into the page as raw html
  const stringToMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const onEdit = async () => {
    setIsEditing(true);
  };

  const onSave = async () => {
    if (christmasList?.id) {
      updateChristmasList({
        ...editorValue,
      });
    } else {
      createChristmasList({
        ...editorValue,
      });
      setHasList(true);
    }

    setIsEditing(false);
  };

  const onEditorChange = async (value) => {
    setEditorValue({
      ...editorValue,
      gifts: value,
    });
  };

  useEffect(() => {
    if (!christmasListIsLoading && !isFetching && christmasList?.id) {
      setHasList(true);
      setEditorValue({
        ...christmasList,
      });
    }
  }, [christmasListIsLoading, christmasList, isFetching]);

  return (
    <Paper elevation={4} sx={{ padding: 4 }}>
      <Stack spacing={2}>
        <ThemeProvider theme={christmasFont}>
          <Typography variant="h2" color="secondary.dark">
            {name}
          </Typography>
        </ThemeProvider>
        {christmasListIsError ? (
          <Alert severity="error">
            Failed to get {name}'s list, please refresh the page. If the problem
            persists talk to tech support.
          </Alert>
        ) : createChristmasListIsError || updateChristmasListIsError ? (
          <Alert severity="error">
            Failed to save {name}'s list, please refresh the page and try again.
            If the problem persists talk to tech support.
          </Alert>
        ) : isEditing ? (
          <Box>
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              value={editorValue?.gifts}
              onChange={onEditorChange}
            />
          </Box>
        ) : !christmasListIsLoading && !isFetching ? (
          hasList ? (
            <Typography variant="body1">
              <span
                dangerouslySetInnerHTML={stringToMarkup(editorValue?.gifts)}
              />
            </Typography>
          ) : (
            <Typography variant="body1">
              Start your Christmas list today!
            </Typography>
          )
        ) : (
          <Skeleton height={40} sx={{ width: 1 }} />
        )}
        {/* this box stops the flex styles from cascading from Stack */}
        <Box>
          {!christmasListIsLoading && !isFetching ? (
            isEditing ? (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => onSave(id)}
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => onEdit()}
              >
                Edit List
              </Button>
            )
          ) : (
            <Skeleton width={102} height={64} />
          )}
        </Box>
      </Stack>
    </Paper>
  );
};

export default ChristmasList;
