import { ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { christmasFont } from "../theme/themes";
import christmasPattern from "../images/christmas_pattern.png";

const AppHeader = ({ year }) => {
  return (
    <Box
      height={1}
      sx={{
        backgroundImage: `url(${christmasPattern})`,
        backgroundRepeat: "repeat",
      }}
    >
      <Container height={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 100,
          }}
        >
          <ThemeProvider theme={christmasFont}>
            <Typography
              variant="h1"
              align="center"
              color="primary"
              sx={{ verticalAlign: "bottom" }}
            >
              Secret Santa {year}
            </Typography>
          </ThemeProvider>
        </Box>
      </Container>
    </Box>
  );
};

export default AppHeader;
