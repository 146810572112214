import { useState } from "react";
import AppHeader from "./components/AppHeader";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "./components/TabPanel";
import christmasBackground from "./images/christmas_background.jpg";
import SecretSanta from "./components/SecretSanta";
import ChristmasLists from "./components/ChristmasLists";

function App() {
  const [tab, setTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const year = new Date().getFullYear();

  return (
    <Box>
      <AppHeader year={year} />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Container>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Secret Santa" />
            <Tab label="Christmas Lists" />
          </Tabs>
        </Container>
      </Box>
      <Box
        sx={{
          minHeight: 900,
          maxHeight: 900,
          backgroundImage: `linear-gradient(to bottom, transparent, 85%, white),
            linear-gradient(to top, transparent, 85%, white),
            url(${christmasBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
          overflow: "visible",
        }}
      >
        <TabPanel value={tab} index={0}>
          <SecretSanta />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <ChristmasLists year={year} />
        </TabPanel>
      </Box>
    </Box>
  );
}

export default App;
